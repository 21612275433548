import React from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../CardsContainer.module.css";

const ViewDetailsRow = ({
  item,
  index,
  sizes,
  addColor,
  replaceColors,
  buttons,
  closer
  // shared,
}) => {
  const { t } = useTranslation();
  return (
    <Row className={`m-0 p-2 ${style.cardRow}`}>
      <Col xs={2} lg={1} className="m-0 p-0" style={{ alignSelf: "center" }}>
        <Image
          src={item.image ? item.image[0].imageURL : item.imageURL}
          style={{ aspectRatio: "4/5" }}
          width="100%"
          alt="balloonImg"
        />
      </Col>
      <Col xs={10} lg={11}>
        <Row className={style.dataCols}>
          <Col xs={5} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("SWATCH NAME")}</h3>
            <small>{item.color}</small>
            <div style={{backgroundColor: item.color, width: '50px', height: '20px'}}></div>
            <p className={style.data}>{item.displayName || "-"}</p>
             <small>{item.distance}</small>
          </Col>
          <Col xs={7} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("BRAND & COLOR NAME")}</h3>
            {item.combined ? (
              <>
                <h5 className={style.secondaryTitle}>{t("OUTSIDE/INSIDE")}</h5>
                <p className={style.data}>
                  {item.brand.split("/")[0]} - {item.outsideColor} /{" "}
                  {item.brand.split("/")[1]} - {item.insideColor}
                </p>
              </>
            ) : (
              <>
                <p className={style.data}>
                  {item.brand} - {item.name}
                </p>
              </>
            )}
          </Col>
          <Col xs={12} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("AVAILABLE SIZES")}</h3>
            <Row>
              {sizes.map((size) => {
                const formattedSize = size.toString().replace(/\./g, "_");
                const baseSize = formattedSize.split("_")[0];
                return (
                  item[`size${formattedSize}`] && (
                    <Col key={formattedSize} xs={"auto"}>
                      <h3 className={style.tableTitle}>
                        {size.toString().includes(".")
                          ? `${baseSize}" LINK`
                          : size > 100
                          ? `${baseSize}`
                          : `${size}"`}
                      </h3>
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
          {index > 0 && buttons || index === 0 && !closer && buttons ? (
            <>
              <Col xs={12} lg={3} className={`${style.rowBtn}`}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      {t("Add this balloon to palette")}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => addColor(item)}
                    className={style.actionBtn}
                  >
                    {t("Add to Palette")}
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      {t("Replace with this balloon")}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => replaceColors(item)}
                    className={style.actionBtn}
                  >
                    {t("Replace")}
                  </Button>
                </OverlayTrigger>
              </Col>
            </>
          ) : buttons && closer && index === 0 ? (
            <Col xs={12} lg={3} className={`${style.rowBtn}`}>
              <Button
                variant="dark"
                size="sm"
                disabled
                className={style.actionBtn}
              >
                {t("In Palette")}
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default ViewDetailsRow;
