import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegImage } from "react-icons/fa6";
import { ImBrightnessContrast } from "react-icons/im";
import { GrNext, GrPrevious, GrRefresh } from "react-icons/gr";
import { GoTrash } from "react-icons/go";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useAppContext } from "../../context";
import { DropZone } from "../index";
import { useScreenSize } from "../../utils";
import style from "./Commands.module.css";
import { NavLink } from "react-router-dom";

const ToolCommands = ({
  // handleRestart,
  handleShowConfirmRestart,
  goToPrev,
  goToNext,
  handleDeleteImage,
  handleBrightnessChange,
  index,
  currentBrightness,
}) => {
  const { images, colormatch } = useAppContext();
  const screenSize = useScreenSize();
  const { t, i18n } = useTranslation();

  const popover = (
    <Popover id="popover-brightness">
      <Popover.Body>
        <Row className={`p-0 m-0 justify-content-between align-items-center`}>
          <Col className="p-0" xs={1}>
            <ImBrightnessContrast />
          </Col>
          <Col xs={6} lg={5} className="p-0">
            <Form.Range
              type="range"
              min="0.5"
              max="1.5"
              step="0.01"
              className={style.rangeBar}
              value={currentBrightness()}
              onChange={(e) => handleBrightnessChange(Number(e.target.value))}
            />
          </Col>
          <Col className="p-0" xs="auto">
            <ImBrightnessContrast size="1.5rem" />
          </Col>
          <Col className="p-0" xs="auto">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleBrightnessChange(1)}
              className={style.actionBtn}
            >
              <GrRefresh />
            </Button>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <Col
      xs={{ order: 0, span: 12 }}
      lg={{ order: 0, span: 6 }}
      className={style.toolCommandsCol}
    >
      <Row
        className={`p-0 m-0 justify-content-center justify-content-lg-start ${style.actionBtnRow}`}
      >
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            variant="secondary"
            onClick={handleShowConfirmRestart}
            size="sm"
            className={style.actionBtn}
          >
            <FaRegImage />
            {t("Restart")}
          </Button>
        </Col>
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <DropZone btn={true} />
        </Col>
        {images.length > 1 && screenSize > 450 && (
          <Col xs="auto" className={`p-0 ${style.prevNextContainer}`}>
            <Button
              variant="dark"
              onClick={goToPrev}
              disabled={index === 0}
              size="sm"
              className={style.actionBtn}
            >
              <GrPrevious />
            </Button>

            <Button
              variant="dark"
              onClick={goToNext}
              disabled={images.length - 1 === index}
              size="sm"
              className={style.actionBtn}
            >
              <GrNext />
            </Button>
          </Col>
        )}
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            variant="dark"
            onClick={handleDeleteImage}
            size="sm"
            className={style.actionBtn}
            disabled={images.length > 0 ? false : true}
          >
            <GoTrash />
          </Button>
        </Col>
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
            rootClose
          >
            <Button
              variant="dark"
              size="sm"
              className={style.actionBtn}
              disabled={images.length > 0 ? false : true}
            >
              <ImBrightnessContrast /> {t("Brightness")}
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Col>
  );
};

export default ToolCommands;
