import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { IoShareOutline } from "react-icons/io5";
import { GoLink } from "react-icons/go";
import {
  Button,
  Popover,
  Row,
  Col,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { app } from "../../firebase";
import { useAppContext } from "../../context";
import { Alert, imagesMapToUpload, useAddPalette } from "../../utils";
import { ExportBtn } from "../index";
import style from "./Commands.module.css";
import {Inputs} from "../index";

const db = getFirestore(app);

const Share = ({ overlay, setOverlay, sharedPalette, sharedPaletteObj }) => {
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const { mutate: addPalette, isLoading, isSuccess } = useAddPalette(true);
  const [loading, setLoading] = useState(false);
  const [paletteId, setPaletteId] = useState(null);
  const [dataChange, setDataChange] = useState(false);
  const [options, setOptions] = useState({
    image: false,
    export: null,
  });
  const [clientName, setClientName] = useState("");
  const [paletteName, setPaletteName] = useState("");
  const location = useLocation();

  const isInitialMount = useRef(true);

  const { i18n, t } = useTranslation();

  useEffect(()=>{
    if(!overlay){
      setOptions((prevState) => ({
        ...prevState,
        export: null,
      }))
      setClientName("");
      setPaletteName("");
    }
  }, [overlay])

  useEffect(() => {
    // Si no es el primer render, actualiza dataChange
    if (!isInitialMount.current) {
      setDataChange(true);
      setPaletteId(null);
    } else {
      // Después del primer render, cambia el valor del re
      isInitialMount.current = false;
    }
  }, [colormatch, images, options]);

  const handleSharePalette = useCallback(() => {
    setLoading(true);
    const newDocRef = doc(collection(db, "sharedPalettes"));
    const newId = newDocRef.id;
    return new Promise((resolve, reject) => {
      imagesMapToUpload({
        images,
        setProgress,
        collection: "sharedPalettes",
        id: newId,
      })
        .then((imagesWithURL) => {
          const paletteToSave = {
            images: imagesWithURL,
            colors: colormatch,
            showImage: options.image,
            type: options.export,
            createdAt: serverTimestamp(),
            user: user,
            clientName: clientName,
            paletteName: paletteName
          };

          return addPalette(
            { palette: paletteToSave, id: newId },
            {
              onSuccess: (docId) => {
                setPaletteId(docId);
                resolve(docId);
              },
              onError: (error) => {
                Alert.error("Error sharing palette, try again.");
                reject(error);
              },
            }
          );
        })
        .catch((error) => {
          Alert.error("Error processing images, try again.");
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [images, colormatch, options.image, options.export, addPalette, clientName, paletteName]);

  /* const copyToClipboard = async (id) => {
    try {
      navigator.clipboard.writeText(
        `https://colormatch.tech/${i18n.language}/share/${id}`
      );
      Alert.success(`Link Copied! The link will expire in 7 days`);
    } catch (err) {
      console.error("Error al copiar el enlace: ", err);
    }
  };

  const copyLinkOfSavedPalette = useCallback(async () => {
    if (sharedPalette) {
      try {
        await navigator.clipboard.writeText(
          `${window.location.protocol}//${window.location.host}${location.pathname}`
        );
        Alert.success(`Link Copied to clipboard!`);
      } catch (error) {
        console.log(error);
      }
      return;
    }

    let id = paletteId;
    if (dataChange) {
      setDataChange(false);
      id = await handleSharePalette();
    }
    if (id && !loading) {
      await copyToClipboard(id);
    }
  }, [paletteId, dataChange, loading, handleSharePalette]); */


  const copyLinkOfSavedPalette = useCallback(async () => {
    try {
      let url = '';
      if (sharedPalette) {
        url = `${window.location.protocol}//${window.location.host}${location.pathname}`;
      } else {
        let id = paletteId;
        if (dataChange) {
          setDataChange(false);
          id = await handleSharePalette();
        }
        if (id && !loading) {
          url = `https://colormatch.tech/${i18n.language}/share/${id}`;
        }
      }
  
      // Copiar al portapapeles si tenemos una URL
      if (url) {
        await navigator.clipboard.writeText(url);
        if(sharedPalette){
          Alert.success(`Link Copied!`);
        }else{
          Alert.success(`Link Copied! The link will expire in 7 days`);
        }
      }
    } catch (error) {
      console.log("Error al copiar el enlace: ", error);
    }
  }, [paletteId, dataChange, loading, handleSharePalette, sharedPalette, i18n.language]);

  const handleInputChange = (e)=>{
    const { name, value } = e.target;
    if(name==='clientName'){
      setClientName(value);
    }else{
      setPaletteName(value);
    }
  }

  const popover = (
    <Popover id="popover-share">
      <Popover.Body>
        <Row className="justify-content-center">
          {!sharedPalette && (
            <>
              <Col xs={12}>
                {/* <h5 className={style.optionsTitle}>Export Options</h5> */}
                <Form.Check
                  className={style.optionsLabel}
                  id="client view"
                  label={<p>{t('Share Palette with Client')}</p>}
                  name="group1"
                  type="radio"
                  onChange={() =>
                    setOptions((prevState) => ({
                      ...prevState,
                      export: "client",
                    }))
                  }
                  checked={options.export === "client"}
                />
                {options.export === 'client' &&
                  <>
                    <Inputs.FormControl
                      label={t('Client Name:')}
                      name="clientName"
                      value={clientName}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Maria"
                      className={`${style.shareTextInputs} mb-2`}
                    />
                      
                    <Inputs.FormControl
                      label={t('Palette/Event Name:')}
                      name="paletteName"
                      value={paletteName}
                      onChange={handleInputChange}
                      type="text"
                      placeholder={t("Alana's Baby Shower")}
                      className={`${style.shareTextInputs} mb-3`}
                    />
                  </>
                }
                <Form.Check
                  className={style.optionsLabel}
                  id="internal view"
                  label={<p>{t('Share Palette with Balloon Artist')}</p>}
                  name="group1"
                  type="radio"
                  onChange={() =>
                    setOptions((prevState) => ({
                      ...prevState,
                      export: "internal",
                    }))
                  }
                  checked={options.export === "internal"}
                />
              </Col>
              <hr />
              <Col xs={12}>
                {/* <h5 className={style.optionsTitle}>Image Options</h5> */}
                <Form.Check
                  className={style.optionsLabel}
                  name="image"
                  checked={options.image}
                  inline
                  id="Include Inspiration Image"
                  label={t('Include Inspiration Image(s)')}
                  type="checkbox"
                  onChange={() =>
                    setOptions((prevState) => ({
                      ...prevState,
                      image: !prevState.image,
                    }))
                  }
                />
              </Col>
            </>
          )}

          <Col
            xs={12}
            className={`${style.shareBtns} ${
              sharedPalette && style.shareBtnsSharedPalette
            }`}
          >
            <Button
              variant="dark"
              className={`${style.shareBtn} ${
                sharedPalette && style.shareBtnSharedPalette
              }`}
              onClick={copyLinkOfSavedPalette}
              disabled={
                (!sharedPalette && !options.export) || isLoading || loading
              }
            >
              {loading || isLoading ? (
                t('Generating...')
              ) : dataChange || !isSuccess ? (
                <>
                  <GoLink /> {t('Copy Link')}
                </>
              ) : (
                <>
                  {t('Link Copied!')}
                  {/* <small>Expires in 7 days</small> */}
                </>
              )}
            </Button>
            <ExportBtn
              image={options.image}
              exportOption={options.export}
              sharedPalette={sharedPalette}
              sharedPaletteObj={sharedPaletteObj}
              user={user}
              paletteName={paletteName}
              clientName={clientName}
            />
          </Col>
          {paletteId && (
            <Col className="mt-2" xs="auto">
              <NavLink
                target="_blank"
                to={`/${i18n.language}/share/${paletteId}`}
                className={style.optionsSubLabel}
              >
                {`https://colormatch.tech/${i18n.language}/share/${paletteId}`}
              </NavLink>
            </Col>
          )}
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {!sharedPalette ? (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={popover}
          containerPadding={20}
          rootClose
          onExit={() =>
            /* //Resetea el state
          setOptions((prevState) => ({
            ...prevState,
            image: false,
            export: null,
          })) */
            setOverlay((prevState) => ({
              ...prevState,
              share: false,
            }))
          }
        >
          <Button
            variant="secondary"
            className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
            disabled={
              !sharedPalette ? (colormatch.length > 1 ? false : true) : false
            }
            onClick={() =>
              setOverlay((prevState) => ({
                ...prevState,
                share: true,
              }))
            }
          >
            <IoShareOutline /> {t('Share')}
          </Button>
        </OverlayTrigger>
      ) : (
        <Col xs={12} className={`${style.shareBtnsSharedPalette}`}>
          <Button
            variant="dark"
            className={`${style.actionBtn} ${style.shareBtnSharedPalette}`}
            onClick={copyLinkOfSavedPalette}
          >
            {loading || isLoading ? (
              "Generating..."
            ) : dataChange || !isSuccess ? (
              <>
                <GoLink /> Copy Link
              </>
            ) : (
              <>
                Link Copied!
                {/* <small>Expires in 7 days</small> */}
              </>
            )}
          </Button>
          <ExportBtn
            image={options.image}
            exportOption={sharedPaletteObj.type}
            sharedPalette={sharedPalette}
            sharedPaletteObj={sharedPaletteObj}
            user={sharedPaletteObj.user}
            paletteName={sharedPaletteObj.paletteName}
            clientName={sharedPaletteObj.clientName}
          />
        </Col>
      )}
    </>
  );
};

export default Share;
